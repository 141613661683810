/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .main-board {
  width: 80%;
  display: block;
  margin: 0 auto;
} */

body {
	margin: 0;
	padding: 0;
}

nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100px;
	width: 100%;
}

ul {
	list-style-type: disc;
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
}

a {
	text-decoration: none;
}

p {
	/* margin-top:12px; */
	/* line-height: 1.6rem; */
	/* margin-bottom: 12px; */
}

.container {
	max-width: 1440px
}

.col {
	min-width: 300px;
}

.main-logo {
	width: 185px;
	height: auto;
	margin: 0px 20px;
}

.main-logo:hover {
	padding-bottom: 10px;
	transition: all 0.5s ease-in-out;
}

.top-nav {
	list-style: none;
	margin-right: 20px;
}

.toast-row {
	position: sticky;
	top: 0;
}

.toast-add,
.toast-remove {
	background-color: rgb(198, 212, 237);
	float: right;
	margin-right: -10px;
}

.toast-remove {
	background-color: rgb(240, 174, 174);
}

.project-grid-header {
	font-weight: bold;
	margin-bottom: 10px;
}

.project-grid-row {
	margin: 5px 0;
	padding: 5px 0;
}

.project-grid-col {
	background-color: rgba(0, 48, 135, 0.1);
	padding: 5px 0
}

.project-grid-med-col {
	padding-left: 24px;
}

.project-grid-small-col {
	padding-left: 18px;
}

.landing-key-points {
	margin-top: 100px;
}

/* DASHBOARD */
.dashboard-user {
	padding: 10px 0;
	font-size: 16px;
	margin: 5px 5px;
	border: 1px solid #003087;
	border-radius: 5px;
	color: #003087;
}

.dashboard-user-text {
	padding: 5px;
	font-weight: bold;
	border: 1px solid lightgray;
	background-color: rgb(0, 48, 135);
	color: white;
	border-radius: 5px;
}

/* .main-background, .main-background-no-img {
	background: linear-gradient(rgba(135, 206, 250, 0.6), rgba(0, 0, 0, 0.7)),
		url('./images/construcion_main.jpg');
	background-position: center;
	width: 100%;
	height: 520px;
	background-size: cover;
	position: relative;
	margin: 0;
	box-shadow: 1px 1px 3px black;
}
.main-background-no-img {
	height: auto;
}
not use
.over-lay {
	background-color: rgba(39, 39, 39, 0.8);
	box-shadow: 2px 2px 8px black !important;
} */
.top-nav-list {
	display: inline;
	line-height: 1.6;
	padding: 0px 20px;
	font-size: 20px;
	font-weight: 600;
	color: black;
}

.top-nav-list:hover {
	opacity: 0.7;
}

.link {
	border-bottom: 1px solid transparent;
	display: inline;
}

.link:hover {
	border-bottom: 1px solid lightskyblue;
}

.caption-message {
	font-size: 50px;
	font-weight: 700;
}

.caption-message-center {
	margin: 0px auto;
	margin-top: 60px;
	font-size: 36px;
	font-weight: 600;
	max-width: 700px;
	text-align: center;
}

.intro-message-div {
	margin-top: 10px;
}

.intro-message-header {
	color: #616161;
	margin-bottom: 5px;
	font-size: 20px;
}

.intro-message {
	color: white;
	margin-top: -15px;
	margin-bottom: 5px;
	padding-bottom: 10px;
}

.pricing-card,
.pricing-card-disable {
	margin: 35px auto;
	background-color: white;
	border-radius: 20px;
	max-width: 355px;
	min-height: 450px;
}

.pricing-card-disable {
	background-color: gray;
	opacity: 0.8;
}

h4 {
	font-family: 'Quicksand';
	font-size: 16px;
	margin: 5px 0px 5px 0px;
}

.h3-big-blue-header {
	color: #003087;
	font-size: 36px;
	font-weight: 800
}

.breakdown-title {
	font-size: 18px;
	margin-bottom: 15px;
	font-weight: 600;
	text-align: center;
}

.breakdown-header {
	margin-bottom: 5px;
	color: rgb(32, 138, 174);
	font-weight: 600;
	display: flex;
	justify-content: space-between;
}

.highlight-cost {
	color: rgb(32, 138, 174);
	/* border-top:0.5px solid rgba(105,206, 250, 0.4); */
	border-bottom: 0.5px dotted rgb(32, 138, 174);
	padding: 2px 0px;
	padding-bottom: 5px;
	margin-bottom: 15px;
}

.error-input {
	font-size: 12px;
	font-weight: 800;
	margin-bottom: 3px;
	margin-top: -3px;
	color: red
}

/* .container {
	background-color: rgb(39, 39, 39);
} */
.main-board {
	margin: 0px auto;
	margin-bottom: 20px;
	border-radius: 15px;
	justify-content: space-between;
}

/* not use */
.main-board2 {
	margin: 0 10%;
}

.customer-board,
.report-board,
.inputs-board {
	display: flex;
	justify-content: space-around;
}

.range-input {
	display: flex;
	align-items: center;
	height: 44px
}

.project-info-range-input {
	width: 250px;
	border: 1px solid rgba(105, 206, 250, 0.4);
	border-radius: 5px;
	background-color: black;
}

/* not use */
.input-wrapper {
	margin-bottom: 20px;
}

fieldset {
	box-shadow: 4px 4px 8px gray;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-block-start: 0;
	padding-inline-start: 2rem;
	padding-inline-end: 2rem;
	padding-block-end: 2rem;
}

.field-set {
	border: 1px solid #003087;
	margin-bottom: 20px;
	border-radius: 15px;
}

.legend {
	margin: 15px
}

.legend-text {
	font-size: 18px;
	font-weight: bold;
	text-shadow: 2px 2px 3px lightgray;
}

/* project info not use */
.project-info,
.project-input,
.project-report {
	/* color: rgba(238, 240, 242, 0.7); */
	margin: 0px 20px;
	margin-bottom: 20px;
}

.project-input {
	margin: 0 10px;
}

.project-report {
	margin: 0 10px;
	min-width: 500px;
}

/* GENERAL STUFF */
.general-back-col {
	margin-left: 15px;
	padding: 5px;
	border-radius: 5px;
}

.general-back-col:hover {
	background-color: rgba(0, 48, 135, 0.2);
	cursor: pointer;
}

.general-signout:hover {
	cursor: pointer;
}

.general-background-div {
	background-color: #F7F9FE;
	margin-top: 70px
}

.general-input-field,
.project-info-input1,
.project-info-input2,
.project-info-input3,
.project-input-textarea {
	border: 1px solid rgba(105, 206, 250, 0.4);
	margin-top: 3px;
	margin-bottom: 10px;
	height: 30px;
	width: 280px;
	padding-left: 5px;
	border-radius: 5px;
	background-color: rgba(252, 252, 252, 0.03);
	box-shadow: 2px 2px 4px gray;
}

.project-info-input3 {
	width: 289px;
	height: 35px;
}

:hover.project-info-input1,
:hover.project-info-input2,
:hover.project-info-input3 {
	background-color: rgba(0, 0, 0, 0.1);
}

.search-bar {
	width: 500px;
	height: 35px;
	margin: 20px;
	background-color: lightgray;
	border: none;
	border-radius: 5px;
	font-size: 15px;
	padding-left: 10px
}

.search-bar:hover {
	background-color: white;
}

.project-input-range {
	width: 267px;
	cursor: ew-resize;
	height: 30px;
}

.project-input-textarea {
	width: 600px;
	height: 150px;
}

.general-input-field {
	margin-bottom: 0;
}

.radio-button {
	width: 15px;
	height: 15px;
	margin-left: 10px;
	margin-right: 35px;
	padding-top: 10px;
}

.radio-button-label {
	margin-left: 10px;
}

.button {
	font-size: 18px;
	width: 250px;
	height: 50px;
	border: 1px solid lightskyblue;
	border-radius: 5px 5px 5px 5px;
	text-align: center;
	margin: 0 auto;
	box-shadow: 3px 3px 9px darkgray;
	cursor: pointer;
}

.button:hover {
	transition: 0.5s ease;
	background-color: rgba(135, 206, 250, 0.5)
}

.report-button {
	text-align: center;
}

.delete-button {
	background-color: rgba(0, 48, 135, 0.1);
	cursor: pointer;
	border: none;
	padding: 8px 10px
}

.delete-button:hover {
	color: #0a58ca;
}

h2 {
	margin-left: 50px;
	padding-bottom: 5px;
}

.title {
	margin: 30px auto;
	text-align: center;
	width: 50%;
	color: rgb(0, 48, 135);
	border-bottom: 0.5px dotted rgb(0, 48, 135)
}

.toplevel-breakdown {
	margin-top: 0;
	padding: 0 7px;
	margin-bottom: 10px;
	border: 0.3px solid rgba(105, 206, 250, 0.4);
	box-shadow: 3px 1px 8px gray;
}

.sublevel-breakdown {
	font-weight: 600;
	font-style: italic;
}

li {
	list-style: none;
	display: flex;
	justify-content: space-between;
	padding: 2px 0px;
	margin: 5px 0px;
}

.break-line {
	border-bottom: 1px dotted lightskyblue;
	margin-bottom: 35px;
}

.report-breakline {
	color: rgb(32, 138, 174);
	border-top: 1px solid rgba(135, 206, 250, 0.3);
	text-align: center;
}

/* --------Signup Section------ */
.signup-signin-box {
	min-width: 350px;
	max-width: 600px;
}

.nav-sign-up-button,
.nav-sign-in-button {
	width: 12rem;
	margin-right: 1rem;
	background-color: #003087;
	color: white;
	border-color: #003087;
	height: 54px;
	margin-top: 10px
}

.nav-sign-up-button:hover {
	text-decoration: underline;
	background-color: #003087;
	color: white;
}

.nav-sign-in-button {
	background-color: white;
	color: #003087;
	border-color: #003087;
}

.nav-sign-in-button:hover {
	text-decoration: underline;
	border-color: #003087;
	color: #003087;
	background-color: white;
}

.nav-sign-in-button:active {
	background-color: white !important;
	color: #003087 !important
}

/* not use */
.signup-title {
	font-size: 20px;
	text-align: center;
	margin-top: 50px;
	position: relative;
	font-weight: bold;
}

/* not use */
.signup-color-box {
	margin-top: 250px;
	height: 350px;
	background: linear-gradient(rgba(135, 206, 250, 0.7), rgba(0, 0, 0, 0.7));
	position: absolute;
	z-index: -1;
}

/* get update box not use */
.signup-box,
.get-update-box {
	margin: 0 auto;
	margin-top: 20px;
	background-color: white;
	width: 370px;
	height: 700px;
	border: 1px solid lightskyblue;
	border-radius: 15px;
	box-shadow: 2px 2px 6px rgb(0, 0, 0);
}

.book-demo-box {
	max-width: 900px;
	min-width: 300px;
	margin: 20px auto;


}

/* not use */
.signup-div-wrap {
	width: 300px;
	margin: 0 auto;
	margin-top: 25px;
}

.label-input-clearance {
	margin-bottom: 25px;
}

.signup-error-message {
	width: 300px;
	height: 5px;
	text-align: left;
	padding-left: 2px;
	font-size: 12px;
	color: red;
}

/* not use */
.signup-input {
	width: 280px;
	height: 35px;
	margin: 10px auto;
	border: 0.5px solid lightskyblue;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
	border-radius: 8px;
	color: rgba(39, 39, 39, 0.7);
	padding-left: 15px;
}

.signup-button {
	text-align: center;
	margin-top: 15px;
}

.signup-button:hover {
	transform: scale(1.05);
}

/* not use */
.signup-button-label {
	border: none;
	text-align: center;
	margin: 10px auto;
	width: 200px;
	height: 40px;
	background-color: lightskyblue;
	color: white;
}

.signup-agreement {
	margin: 15px 0 15px 0;
	text-align: center;
}

/* Pagination */
.page-link {
	color: #003087;
}

.active>.page-link {
	background-color: #003087;
	border: #003087;
}

/* Stripe Payment Box */
.payment-form {
	margin: 0 auto;
	align-self: center;
	box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
		0px 2px 5px 0px rgba(50, 50, 93, 0.1),
		0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 40px;
}

.payment-button {
	background: #5469d4;
	font-family: Arial,
		sans-serif;
	color: #ffffff;
	border-radius: 4px;
	border: 0;
	padding: 12px 16px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	transition: all 0.2s ease;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
	width: 100%;
	margin-top: 20px
}

.agreement-message {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
	margin: 0 auto;
}

#payment-message-success,
#payment-message-failed {
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
}

#payment-message-success {
	color: rgb(32, 138, 174);
	font-weight: bold;
}

#payment-message-failed {
	color: red
}

/* not use */
.payment-modal-box {
	height: 400px;
	background-color: white;
	border: 1px solid darkgray;
	border-radius: 20px;
	margin-bottom: 50px;
}

.payment-success-box {
	text-align: center;
}

.payment-success {
	color: black;
	margin: 10px auto;
	margin-top: 80px
}

.payment-check-mark {
	color: white;
	font-size: 44px;
	margin-top: -2px
}

.payment-success-check {
	border: 1px solid white;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	margin: 0 auto;
	background-color: lightskyblue;
}

.spinner,
.spinner:before,
.spinner:after {
	border-radius: 50%;
}

.spinner {
	color: #ffffff;
	font-size: 22px;
	text-indent: -99999px;
	margin: 0px auto;
	position: relative;
	width: 20px;
	height: 20px;
	box-shadow: inset 0 0 0 2px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

.spinner:before,
.spinner:after {
	position: absolute;
	content: '';
}

.spinner:before {
	width: 10.4px;
	height: 20.4px;
	background: #5469d4;
	border-radius: 20.4px 0 0 20.4px;
	top: -0.2px;
	left: -0.2px;
	-webkit-transform-origin: 10.4px 10.2px;
	transform-origin: 10.4px 10.2px;
	-webkit-animation: loading 2s infinite ease 1.5s;
	animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
	width: 10.4px;
	height: 10.2px;
	background: #5469d4;
	border-radius: 0 10.2px 10.2px 0;
	top: -0.1px;
	left: 10.2px;
	-webkit-transform-origin: 0px 10.2px;
	transform-origin: 0px 10.2px;
	-webkit-animation: loading 2s infinite ease;
	animation: loading 2s infinite ease;
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* -------Sign In Section--------  */
.signin-box {
	margin: 0 auto;
	border-radius: 10px;
	max-width: 600px;
}

.form-wrapper {
	width: 400px;
	padding-top: 5px;
}

.signin-button-wrapper {
	width: 350px;
	margin: 0 auto;
}

.signin-button {
	background-color: #003087;
	color: white;
}

.signin-button-label {
	margin: 20px 40px;
	color: lightskyblue;
	text-align: center;
}

.signin-message {
	color: black;
	text-align: center;
	margin-left: 5px;
	transition: all 0.7s ease-in-out;
	-webkit-transition: all 0.7s ease-out;
	-moz-transition: all 0.7s ease-out;
	-o-transition: all 0.7s ease-out;
	transition: all 0.7s ease-out;
}

.not-signin-message {
	text-align: center;
	color: red;
}

/* --------Footer Section------ */
.footer-logo {
	max-width: 200px;
	margin-bottom: 50px;
}

.footer-link {
	color: white;
	margin: 0 auto;
	max-width: 300px;
	min-width: 300px;
}

.footer-div {
	width: 100%;
	min-height: 500px;
	background-color: #003087;
}

.footer-divider {
	border-top: 1px solid rgba(135, 206, 250, 0.3);
}

/* --------------Analysis Section--------- */
.analysis {
	width: 500px;
	margin: 30px auto;
	display: block;
	text-align: center;
	color: #003087;
	border-bottom: 1px dotted #003087;
	padding-bottom: 20px;
}

.upload-icon {
	width: 35px;
	height: auto;
}

.input-file {
	z-index: -1;
	display: none;
}

.header4 {
	font-size: 20px;
}

.input-file-label {
	margin: 20px auto;
	height: 50px;
	width: 300px;
	border: 1px solid #003087;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 4px;
}

.input-file-label:hover,
.general-button:hover {
	opacity: 1;
	transition: 0.5s ease;
	color: rgb(32, 138, 174);
}

.choose-file {
	padding-left: 10px;
}

.general-bootstrap-btn {
	background-color: #003087;
	color: white;
	max-width: 300px;
	min-width: 150px;
	height: 50px;
}

.general-button {
	font-size: 15px;
	margin-top: 20px;
	margin-left: 5px;
	width: 150px;
	height: 35px;
	background-color: white;
	color: black;
	border: 0.5px solid lightskyblue;
	border-radius: 5px;
	box-shadow: 2px 2px 3px gray;
	opacity: 0.8;
	cursor: pointer;
}

.general-button:hover {
	background-color: lightskyblue;
	color: white
}

.general-button-style2 {
	font-size: 16px;
	margin-top: 10px;
	width: 150px;
	height: 35px;
	border: 0.5px solid lightskyblue;
	background-color: lightskyblue;
	margin-left: 30px;
	border-radius: 8px;
}

.general-button-style2:hover {
	background-color: white;
}

.add-button {
	font-size: 14px;
	width: 200px;
	height: 100%;
	opacity: 0.8;
	cursor: pointer;
}

.add-button:hover {
	font-weight: bolder;
	opacity: 1;
	color: lightskyblue
}

.loading-block {
	width: 100%;
	height: 30px
}

.loading {
	display: block;
	margin: 0 auto;
	border: 7px solid rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	border-bottom: 7px solid rgba(0, 48, 135, 0.8);
	border-top: 7px solid rgba(0, 48, 135, 0.8);
	border-right: 7px solid rgba(0, 48, 135, 0.8);
	width: 40px;
	height: 40px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 1s linear infinite;
}

.not-loading {
	display: none;
}

/* bootstrap stuff */

.bootstrap-loading {
	display: inline-block;
	margin-right: 10px;
}

.spinner-border {
	--bs-spinner-width: 1rem;
	--bs-spinner-height: 1rem;
	--bs-spinner-border-width: 0.15em;
}

.form-control-focus {
	border-color: rgba(0, 48, 135, 0.8);
	color: #003087;
	font-weight: 600;
}

.form-select-focus {
	color: #003087;
	font-weight: 600;
	border-color: rgba(0, 48, 135, 0.8);
}

.bold-blue {
	color: #003087;
	font-weight: 600;
}

@webkit-keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.display-result {
	width: 1350px;
	margin: 0 auto;
	display: flex;
	justify-content: space-evenly;
}

.display-image {
	width: 1000px;
	margin: 30px auto;
	position: relative;
}

.result-image {
	width: 1000px;
	height: auto;
}

.display-cost-result {
	margin: 0 20px
}

.general-table-row {
	min-width: 1000px;
	text-align: left;
}

td {
	min-width: 180px;
}

td,
th {
	padding-bottom: 5px;
}

.link-color {
	color: rgba(0, 0, 0, 0.9);
}

.link-color2 {
	color: lightskyblue;
	opacity: 0.8;
}

.link-color2:hover {
	opacity: 1;
	font-weight: bolder;
}

.link-color3 {
	color: black;
	cursor: pointer
}

.link-color3:hover {
	font-weight: bold;
	color: red;
}

/* general styling common*/
.general-thumbnail-image {
	/* width: 100%;
	height: auto; */
	margin-left: 15px
}

/* maybe not use */
.general-header {
	color: lightskyblue;
	font-size: 20px;
	font-family: "Lato";
	margin-left: 10px;
	text-align: center;
}

.pdf-viewer {
	width: 100%;
	height: 100%;
}

.general-div-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.general-card-view {
	margin: 10px 30px;
	color: rgba(238, 240, 242, 0.7);
	border: 2px solid rgba(135, 206, 250, 0.5);
	border-radius: 15px 0px 15px 0px;
	text-align: left;
	padding-left: 10px;
}

.general-card-view:hover {
	border-radius: 0px 15px 0px 15px;
	box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
	transition: 0.5s ease;
}

.pdf-report-wrapper {
	max-width: 1440px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.pdf-download-wrapper {
	border-top: 2px solid lightskyblue;
	border-bottom: 2px solid lightskyblue;
}

.pdf-report-col,
.pdf-download-button {
	margin: 0 auto;
}

.pdf-download-button {
	width: 300px;
}

.pdf-report-header {
	max-width: 1440px;
	text-align: left;
	padding: 10px;
	border-bottom: 2px solid lightskyblue
}

.font-awesome {
	padding-right: 10px;
	color: #003087;
}

.add-icon {
	font-size: 40px;
	margin-bottom: -11px;
	cursor: pointer;
}

.add-icon:hover {
	color: lightskyblue
}

.pdf-download-button {
	padding: 10px 0;
	border: 2px solid lightskyblue;
	text-align: center;
	border-radius: 15px 0 15px 0;
	font-size: 30px;
	padding-right: 0;
}

.pdf-download-button:hover {
	border-radius: 0 15px 0 15px;
	transition: 0.5s ease;
	background-color: rgba(135, 206, 250, 0.2);
	color: white;
}

.password-reset {
	max-width: 500px;
	margin: 50px auto;
	padding: 30px;
	text-align: center;
	border: 1px solid darkgray;
	min-height: 250px;
}

.password-reset-input {
	width: 280px;
	min-width: 250px;
	height: 40px;
	border: 1px solid rgb(0, 48, 135);
	border-radius: 5px;
}

.general-list-wrapper {
	margin-top: 10px;
	margin-bottom: 10px
}

.general-list-header {
	margin-left: 5px;
	padding-top: 10px;
	font-weight: bold;
}

.general-modal {
	position: relative;
	background-color: white;
	height: 350px;
	margin: 0 auto;
	padding: 30px;
	text-align: center;
	border: 1px solid darkgray;
	margin-top: -380px;

}

.general-row-wrapper {
	max-width: 1440px;
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.general-row {
	max-width: 1320px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	text-align: left;
	margin: 5px 0px 5px 5px;
}

.general-profile {
	width: 50px;
	height: auto;
	border-radius: 25px;
	background-color: rgba(238, 240, 242, 0.3);
	margin: 5px 10px 5px 0px;
}

.general-profile-view {
	max-width: 50px;
	display: flex;
	font-size: 30px;
	align-items: center;
	padding-left: 10px;
}

/* ---------Responsive---------- */
@media (max-width: 1024px) {
	h2 {
		margin-left: 0px;
	}

	fieldset {
		padding-inline-start: 0rem;
		padding-inline-end: 0rem;
		padding-block-end: 0rem;
	}

	.main-board {
		width: 96%;
	}

	.customer-board,
	.report-board,
	.inputs-board {
		display: block;
	}

	.project-input {
		padding-left: 10px;
	}

	.display-result {
		width: 1000px;
		display: block;
	}
}

@media (max-width: 768px) {
	.caption-message-div {
		width: 650px;
	}

	fieldset {
		padding-inline-start: 0rem;
		padding-inline-end: 0rem;
		padding-block-end: 0rem;
	}

	.wrapper {
		display: block;
	}

	.top-nav-list {
		font-size: 16px;
	}

	.display-result {
		width: 700px;
	}

	.display-image {
		width: 700px;
	}

	.result-image {
		width: 700px;
	}
}

@media (max-width: 540px) {
	.sidebar-area {
		margin-top: 100px;
		height: auto;
		margin-bottom: 10px;
	}

	.general-profile-view {
		margin: 0 auto;
	}

	.project-grid-header {
		display: none;
	}

	.project-grid-row {
		text-align: center;
		border: 1px solid rgb(0, 48, 135);
	}

	.project-grid-col {
		background-color: #F7F9FE;
	}

	.image-col {
		display: none;
	}

	.payment-form {
		padding: 20px
	}

	.landing-key-points {
		margin-top: 50px;
		text-align: center;
	}
}

@media (max-width: 414px) {
	.general-profile-view {
		display: block;
		max-width: 100%;
	}

	.description-input {
		margin-bottom: 5px
	}

	.wrapper {
		display: block;
	}

	nav {
		display: block;
		height: 235px;
	}

	.top-nav-list {
		display: block;
		padding: 2px 30px;
		font-size: 16px;
		text-align: center;
	}

	.title {
		width: 70%;
	}

	.signup-box {
		width: 360px;
		height: 750px;
	}

	fieldset {
		box-shadow: 4px 4px 8px black;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-block-start: 0;
		padding-inline-start: 0;
		padding-inline-end: 0;
		padding-block-end: 0;
	}

	.signin-box {
		margin-top: 20px;
		width: 340px;
	}

	.form-wrapper {
		width: 345px;
	}

	.caption-message-div,
	.intro-message-div {
		width: 350px;
	}

	.intro-message {
		margin-bottom: 0px;
		padding-bottom: 5px;
	}

	.caption-message-div {}

	.footer-div {
		margin-top: 250px;
	}

	.project-info-input1,
	.project-info-input2,
	.project-info-input3 {
		margin-left: 5px;
	}

	.project-info,
	.project-input {
		margin: 0px 5px;
		padding-left: 5px;
	}

	.customer-board,
	.report-board,
	.inputs-board {
		display: block;
		font-size: 14px;
	}

	.main-board2 {
		margin: 0 2%;
	}

	.project-report {
		margin: 0 10px;
		min-width: 330px;
	}

	h4 {
		padding-left: 10px;
	}

	.radio-button-label {
		margin-left: 5px;
	}

	.analysis {
		width: 300px
	}

	.display-result {
		width: 300px;
	}

	.display-image {
		width: 300px;
	}

	.result-image {
		width: 300px;
	}
}

@media (max-width : 320px) {
	td {
		min-width: 180px;
	}

	.project-grid-small-col {
		min-width: 180px !important
	}

	.general-profile-view {
		display: block;
	}

	.top-nav {
		margin-right: 0px;
	}

	.signup-title {
		font-size: 16px;
	}

	.signup-box {
		width: 310px;
		height: 750px;
	}

	.signup-input {
		width: 270px;
		margin: 10px 5px;
	}

	.form-wrapper {
		width: 290px;
	}

	.signin-box {
		width: 290px;
	}

	.project-info-input1,
	.project-info-input2 {
		margin-left: 5px;
		width: 270px;
	}

	.project-info-input3 {
		width: 280px;
	}

	.project-info,
	.project-input {
		width: 290px;
		padding-left: 3px;
	}

	.project-report {
		min-width: 285px;
	}

	.customer-board,
	.report-board,
	.inputs-board {
		font-size: 12px;
	}
}