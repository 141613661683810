.sidebar-area {
    margin-top: 100px;
    height: 100vh;
}
.sidebar-area > a {
    color: black;
}
.sidebar {
    padding: 10px 0;
    font-size: 16px;
    margin:5px 5px;
}
.sidebar:hover, .active-tab {
    color: white;
    background-color: rgb(0, 48, 135);
    border-radius: 5px;
}
.sidebar-icon {
    font-size: 1rem;
    padding-right: 10px;
}
.sidebar > .sidebar-icon {
    color: white;
    padding-right: 10px;
}
.general-row-wrapper-deviation{
    background-color: rgba(255,169,122,0.1);
    color: white;
}
.dashboard-area {
    margin-top: 100px;
    background-color: #F7F9FE ;
}
.header-link{
    color:  rgba(238, 240, 242, 0.7);
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(135, 206, 250, 0.0)
}
.header-link:hover{
    border-bottom: 1px solid lightskyblue
}
.dashboard-header{
    max-width: 1000px;
    width: 800px;
    margin:0 auto;
    display:flex;
    justify-content: space-evenly;
}
.dashboard-header-container{
    margin:30px auto;
    display: flex;
    border-top:1px solid rgba(135, 206, 250, 0.5);
    border-bottom:1px solid rgba(135, 206, 250, 0.5);
    justify-content: center;
}
.dashboard-profile-view{
    color:rgba(238, 240, 242, 0.7);
    min-width: 150px;
    margin-left:20px;
    display:flex
}