
.canvas-container{
    width: 1200px;
    height: 646px;
    margin:0 auto;
    background-size: contain ;
    object-fit: fill;
    margin-top: 50px;
}
.buttons-container{
    width: 1000px;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
}
.analyze-button{
    height:35px;
    width: 150px;
    border: 2px solid lightskyblue;
    border-radius: 5px;
}
/* Responsive */

@media (max-width: 1024px) {
	.canvas-container {
		width: 1000px;
		height: 540px;
	}
}
@media (max-width: 768px) {
	.canvas-container {
		width: 700px;
		height: 377px;
	}
    .buttons-container{
    width: 700px;
}
}
@media (max-width: 414px) {
	.canvas-container {
		width: 310px;
		height: 168px;
	}
    .buttons-container{
    width: 300px;
}

.analyze-button{
    height:30px;
    width: 90px;
    font-size: 9px;
}
}
